<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-7">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="text-muted text-center mt-2 mb-3">
            <h4>You are not allowed to view this section</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {},
  validations: {},
  methods: {},
};
</script>